import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IRecordOfEmploymentTransactionInfo, IRecordOfEmploymentField } from 'src/app/contact/models/roe-report.interface';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-roe-modal',
  templateUrl: './roe-modal.component.html',
  styleUrls: ['./roe-modal.component.less']
})
export class RoeModalComponent {
  @Input() modalTitle: string;
  @Input() roeFields: IRecordOfEmploymentField[];
  @Input() detailedRows: IRecordOfEmploymentTransactionInfo[];
  @Output() closeModal = new EventEmitter<void>();
  
  copiedFields: { [key: string]: boolean } = {};

  constructor(private commonService: CommonService) {}

  copyToClipboard(value: string, index: string): void {
    try {
      navigator.clipboard.writeText(value);
      this.copiedFields[index] = true;
      this.commonService.logSuccess('Copied to clipboard');
    } catch (error) {
      this.commonService.logError('Failed to copy text to clipboard');
    }
  }

  onClose(): void {
    this.closeModal.emit();
  }
} 
