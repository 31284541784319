import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';

import { ApiService, CodeValueService, PhxConstants, PhxLocalizationService, PhxWorkflowDisplayNameService, WindowRefService } from '../../common';
import { ReportAZSearchRoutingConfiguration, ReportConfigurationDto } from '../model/report-azsearch-list';
import { CodeValue, CodeValueGroups, PhxDataTableColumnFormat, PhxDataColumnLookup, PhxDataTableColumn, PhxDataTableConfiguration, PhxDataTableUserProfile } from '../../common/model';
import { environment } from '../../../environments/environment';
import { IReportSecurityViewApiResponse } from '../model/report-security-view';
import { ReportSearchParams } from '../model/report-search-params';
import { Data } from '@angular/router';
import { DownloadCenterService } from '../../download-center/service/download-center.service';
import { OrganizationApiService } from '../../organization/services/organization.api.service';
import { WorkorderService } from '../../workorder/services/workorder.service';
import { AvailableWorkOrder } from '../../expense/model';
import { ReportModuleResourceKeys } from '../model/report-module-resource-keys';
import { assign, filter, union } from 'lodash';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import DxDataGrid from 'devextreme/ui/data_grid';
import { ToastService } from '../../common/services/toast.service';
import ReportColumnType = PhxConstants.ReportColumnType;
import CultureType = PhxConstants.CultureType;
import ReportType = PhxConstants.ReportType;

@Injectable({
  providedIn: 'root'
})
export class ReportAZSearchService {
  private reportUserViewStates = new BehaviorSubject<PhxDataTableUserProfile>(null);
  private apiEndpoint = environment.reportServiceApiEndpoint;
  private organizationDropdownCache: Array<{ text: string, value: string; }> = [];
  private clientOrganizationDropdownCache: Array<{ text: string, value: string; }> = [];
  private branchDropdownCache: Array<{ text: string, value: string; }> = [];

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private downloadCenterService: DownloadCenterService,
    private orgService: OrganizationApiService,
    private workOrderService: WorkorderService,
    private localizationService: PhxLocalizationService,
    private codeValueService: CodeValueService,
    private locale: PhxLocalizationService,
    private winRef: WindowRefService,
    private toastService: ToastService,
    private workflowDisplayNameService: PhxWorkflowDisplayNameService
  ) {
  }

  reportUserViewStates$(): Observable<PhxDataTableUserProfile> {
    return this.reportUserViewStates.asObservable();
  }

  getReportRoutingConfiguration(reportId: number) {
    return this.apiService.query<Array<ReportAZSearchRoutingConfiguration>>('report/getReportRoutingConfiguration/' + reportId);
  }

  getReportConfiguration(reportId: number) {
    return this.apiService.queryAZSearch<Array<ReportConfigurationDto>>(`report/${reportId}/configuration`);
  }

  getReportSecurityViewFilterData() {
    return this.apiService.httpGetRequest<IReportSecurityViewApiResponse>(`reportSecurityView`, this.apiEndpoint, false);
  }

  async exportReport(reportId: number, params: ReportSearchParams, visibleColumns: Array<string>, teamIds: string = '',
    contextualUserIds: string = '', requestedCount: number, reportName: string = ''): Promise<boolean> {
    const headers = new HttpHeaders()
      .append('azsearch-team-ids', teamIds)
      .append('azsearch-contextual-user-ids', contextualUserIds)
      .append('requested-count', `${requestedCount || 0}`)
      .append('report-name', reportName);
    const searchParams = new URLSearchParams();

    Object.keys(params).forEach(key => {
      // Do strict comparison against null and undefined because we need to consider 0 as valid value
      if (params[key] !== null && params[key] !== undefined) {
        const value = key === '$select' ? visibleColumns.join(',') : params[key];
        searchParams.append(key, value);
      }
    });

    return new Promise((resolve) => {
      this.http.get<any>(`${this.apiEndpoint}/report/exportReport/${reportId}?${searchParams.toString()}`, { headers })
        .subscribe({
          next: response => {
            const msg = this.localizationService.translate(ReportModuleResourceKeys.common.preparingForExportMessage);
            this.toastService.showToast(msg, 'notifications', 'success');
            if (response?.id) {
              this.downloadCenterService.detectFileReadiness(response.id);
            }
            resolve(true);
          },
          error: () => {
            const msg = this.localizationService.translate(ReportModuleResourceKeys.common.unableToPrepareReport);
            this.toastService.logError(msg);
            resolve(true);
          }
        });
    });
  }

  getOrgListForDropdown(isDestroyed$: Subject<boolean>): Observable<Array<{ text: string, value: string }>> {
    return new Observable(subscriber => {
      if (this.organizationDropdownCache?.length) {
        subscriber.next(this.organizationDropdownCache);
        subscriber.complete();
      } else {
        this.orgService.getListOrganizationsOriginalAndStatusIsAtiveOrPendingChangeInActiveInternalRole()
          .pipe(takeUntil(isDestroyed$))
          .subscribe(data => {
            this.organizationDropdownCache = data.Items.map((item) => {
              return {
                text: item.DisplayName,
                value: item.Id
              };
            });
            subscriber.next(this.organizationDropdownCache);
            subscriber.complete();
          });
      }
    });
  }

  getBranchListForDropdown(isDestroyed$: Subject<boolean>): Observable<Array<{ text: string, value: string }>> {
    return new Observable(subscriber => {
      if (this.branchDropdownCache?.length) {
        subscriber.next(this.branchDropdownCache);
        subscriber.complete();
      } else {
        this.workOrderService.getBranchList()
          .pipe(takeUntil(isDestroyed$))
          .subscribe(data => {
            this.branchDropdownCache = data.Items.map((item) => {
              return {
                text: item.Name,
                value: item.Id
              };
            });
            subscriber.next(this.branchDropdownCache);
            subscriber.complete();
          });
      }
    });
  }

  getClientOrgListForDropdown(isDestroyed$: Subject<boolean>): Observable<Array<{ text: string, value: string; }>> {
    return new Observable(subscriber => {
      if (this.clientOrganizationDropdownCache?.length) {
        subscriber.next(this.clientOrganizationDropdownCache);
        subscriber.complete();
      } else {
        this.orgService.getListOrganizationClient1()
          .pipe(takeUntil(isDestroyed$))
          .subscribe(data => {
            this.clientOrganizationDropdownCache = data.Items.map((item) => {
              return {
                text: item.DisplayName,
                value: item.Id
              };
            });
            subscriber.next(this.clientOrganizationDropdownCache);
            subscriber.complete();
          });
      }
    });
  }

  async getAvailableWorkOrders(): Promise<Array<AvailableWorkOrder>> {
    try {
      return lastValueFrom(this.apiService.query<Array<AvailableWorkOrder>>(`expenseClaim/availableWorkOrders`));
    } catch (e) {
      return null;
    }
  }

  async createNewExpenseClaim(workOrderId: number) {
    const payload = { WorkOrderId: workOrderId };
    try {
      const res = await this.apiService.command('NewExpenseClaim', payload);
      if (!res.IsValid) {
        return Promise.reject(res.ValidationMessages);
      }
      return Promise.resolve(res.EntityId);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  getCodeValue(codeTable: string) {
    return this.codeValueService
      .getCodeValues(codeTable, true)
      .sort((a, b) => {
        if (a.code < b.code) {
          return -1;
        }
        if (a.code > b.code) {
          return 1;
        }
        return 0;
      })
      .map((codeValue: CodeValue) => {
        return {
          description: codeValue.description,
          id: codeValue.id,
          code: codeValue.code,
          value: codeValue.text
        };
      });
  }

  parseRequiredColumns(data) {
    let reqCols = [];
    if (data) {
      data.forEach(rc => {
        if (rc.Condition) {
          reqCols = union(
            reqCols,
            rc.Condition.match(/(\${)(.*?)(?=})/g).map(x => x.replace('${', ''))
          );
        }
        reqCols = union(
          reqCols,
          rc.Routing.match(/(\${)(.*?)(?=})/g).map(x => x.replace('${', ''))
        );
      });
    }
    return reqCols;
  }

  buildColumns(reportConfigurationColumns: Array<ReportConfigurationDto>, cultureId: number, reportName: string,
    organizations?: Array<{ text: string, value: string; }>, clientOrganizations?: Array<{ text: string, value: string; }>, branches?: Array<{ text: string, value: string; }>,
    resolvedData: Data = {}) {
    const columns = [];

    reportConfigurationColumns.forEach(val => {
      const captionKey = 'report.' + reportName.charAt(0).toLowerCase() + reportName.substring(1) + '.' + val.columnName;
      let caption = this.locale.translate(captionKey);
      if (caption === captionKey && cultureId === CultureType.EnCA ) {
        caption = val.columnNameEnglish;
      }
      else if(cultureId === CultureType.FrCA)
      {
        caption = val.columnNameFrench;
      }

      const dataTableColumn = new PhxDataTableColumn({
        dataField: val.columnName,
        caption,
        dataType: this.getGridColumnDataType(val.columnTypeId),
        format: this.getGridColumnFormat(val.columnTypeId),
        visible: val.visible && val.defaultView,
        showInColumnChooser: val.visible,
        lookup: this.getDataColumnLookup(val, cultureId, organizations, clientOrganizations, branches, resolvedData)
      });

      if (val.columnTypeId === ReportColumnType.String) {
        dataTableColumn.filterOperations = [];
        dataTableColumn.selectedFilterOperation = 'contains';
        dataTableColumn.calculateFilterExpression = (colData => {
          return (filterValue) => {
            return [[colData.dataField, 'contains', filterValue.toString()]];
          };
        })(dataTableColumn);
      }

      if (val.columnTypeId === ReportColumnType.MultiValue) {
        dataTableColumn.allowSorting = false;
        dataTableColumn.allowGrouping = false;
        dataTableColumn.filterOperations = [];
        dataTableColumn.selectedFilterOperation = 'contains';
        dataTableColumn.calculateFilterExpression = (colData => {
          return (filterValue) => {
            return [[colData.dataField, 'contains', filterValue]];
          };
        })(dataTableColumn);
      }

      if (val.columnTypeId === ReportColumnType.String && (val.datasourceName === 'GetBranches' || val.datasourceName === 'GetInternalOrganizations' 
      || val.datasourceName === 'GetClientOrganizations')) {
        dataTableColumn.calculateFilterExpression = (colData => {
          return (filterValue: any[]) => {
            const filterExpressionArray = [];
            filterValue.forEach(item => {
              if (filterExpressionArray.length > 0) {
                filterExpressionArray.push('or');
              }
              filterExpressionArray.push([colData.dataField, '=', item]);
            });
            return filterExpressionArray.length > 0 ? [filterExpressionArray] : null;
          };
        })(dataTableColumn);
      }

      if (val.columnName === 'WorkOrderNumber') {
        dataTableColumn.calculateSortValue = 'AssignmentId';
      }
      
      if (val.columnName === 'DealID') {
        dataTableColumn.calculateSortValue = 'EntityId';
      }

      if (dataTableColumn.lookup) {
        if (dataTableColumn.dataType === 'multiValue') {
          dataTableColumn.dataType = 'string';
          dataTableColumn.calculateDisplayValue = (rowData) => {
            const dataField = dataTableColumn.dataField;
            const commonDisplayValueField = dataField + 'DisplayValue';
            if (!(commonDisplayValueField in rowData)) {
              return this.getMultiValueDisplayValue(rowData,dataField,dataTableColumn);
            }
        
            return rowData[commonDisplayValueField];
      };
          dataTableColumn.calculateSortValue = dataTableColumn.calculateDisplayValue;
          dataTableColumn.calculateFilterExpression = (colData => {
            return (filterValue) => {
              if (Array.isArray(filterValue) && filterValue.length) {
                const searchString = filterValue.join('|');
                return [[colData.dataField, 'contains',  searchString]];
              }
            };
          })(dataTableColumn);
        } else if (dataTableColumn.dataType === 'number') {
          dataTableColumn.dataType = 'string';
        } else if (dataTableColumn.dataType === 'string') {
          dataTableColumn.calculateFilterExpression = (colData => {
            return (filterValue) => {
              if (Array.isArray(filterValue) && filterValue.length) {
                const searchString = filterValue.join('|');
                return [[colData.dataField, 'contains', searchString]];
              }
            };
          })(dataTableColumn);
        }
      }

      columns.push(dataTableColumn);
    });
    return columns;
  }

  getMultiValueDisplayValue(rowData: any, dataField: string, dataTableColumn: PhxDataTableColumn) {
    const ids = rowData[dataField];
    if (!ids) {
        return '';
    }
    const arrayIds = ids?.split(',');
    const lookupValues: any[] = dataTableColumn.lookup?.dataSource || [];
    const filteredArray = lookupValues
    .filter((lookupVal: any) => {
      return arrayIds.some((id: any) => id.toString() === (lookupVal?.value ?? '').toString());
    })
    .map((x: any) => x?.text);
    return filteredArray.join(', ');   
  }

  onToolbarPreparing(e, configuration: PhxDataTableConfiguration) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      template: 'toolbarContent'
    });

    e.toolbarOptions.items.unshift({
      location: 'center',
      template: 'centerToolbarContent'
    });

    if (configuration.showToolbar === false) {
      e.toolbarOptions.visible = false;
    }
  }

  compareFilters(filter1: any, filter2: any): boolean {
    if (Array.isArray(filter1) && Array.isArray(filter2)) {
      if (filter1.length === filter2.length) {
        for (let i = 0; i < filter1.length; i++) {
          if (!this.compareFilters(filter1[i], filter2[i])) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    } else {
      return filter1 === filter2;
    }
  }

  onContextMenuPreparing(event: any, reportRoutingConfig: Array<ReportAZSearchRoutingConfiguration>, configuration: PhxDataTableConfiguration, reportName: string) {
    if (event?.row?.rowType === 'data' && reportRoutingConfig.length > 0) {
      event.items = [];
      reportRoutingConfig.forEach(routingConfigItem => {
        if (routingConfigItem.Condition) {
          const routeTest = this.parseRoutingConfigTemplate(routingConfigItem.Condition, event.row.data, true);
          // eslint-disable-next-line no-eval
          const isTrue = eval(routeTest);
          if (!isTrue) {
            return;
          }
        }
        const route = this.parseRoutingConfigTemplate(routingConfigItem.Routing, event.row.data, false);
        if (route) {
          event.items.push({
            text: `${this.locale.translate('report.' + reportName.charAt(0).toLowerCase() + reportName.substring(1) + '.' + routingConfigItem.RouteName)}`,
            onItemClick: () => {
              return this.winRef.nativeWindow.open(route);
            }
          });
        }
      });
    }
  }

  onEditorPreparing(event) {
    if (event.parentType === 'filterRow' && event.editorName === 'dxSelectBox' && event.lookup) {

      if (event.value && !Array.isArray(event.value)) {
        event.value = [event.value];
      }

      event.editorName = 'dxTagBox';

      const dataSource = Array.isArray(event.lookup.dataSource)
        ? { store: event.lookup.dataSource, paginate: true }
        : event.lookup.dataSource;

      assign(event.editorOptions, {
        dataSource,
        displayExpr: event.lookup.displayExpr,
        valueExpr: event.lookup.valueExpr,
        value: event.value || [],
        showSelectionControls: true,
        showDropDownButton: false,
        showClearButton: false,
        hideSelectedItems: false,
        multiline: false,
        maxDisplayedTags: 1,
        searchEnabled: true,
        activeStateEnabled: false,
        placeholder: this.locale.translate('common.phxDataTable.tagboxPlaceholder'),
        onFocusOut: (e) => {
          const component = e ? e.component : null;
          if (component) {
            component.close();
          }
        },
        onClosed: (e) => {
          const component = e ? e.component : null;
          const value = component ? component.option('value') : null;
          event.setValue(value);
        },
        onOpened: (e) => {
          const list = e?.component ? e.component.content() : null;
          const listParent = list ? list.parentElement : null;
          const width = listParent?.style ? parseInt(listParent.style.width, 10) : null;
          if (width) {
            listParent.style.width = width + 50 + 'px';
          }
        },
        onMultiTagPreparing: (e) => {
          const len = e?.selectedItems ? e.selectedItems.length : 0;
          if (len) {
            e.text = this.locale.translate('common.phxDataTable.tagboxMultiTagText', len);
          } else {
            e.cancel = true;
          }
        },
        onValueChanged: (e) => {
          const component = e ? e.component : null;
          const value = component ? component.option('value') : null;
          const opened = component ? component.option('opened') : false;
          if (!opened) {
            event.setValue(value);
          }
        }
      });
    }

    if (event.parentType === 'filterRow' && event.dataType === 'number') {
      event.editorOptions.min = -2147483648;
      event.editorOptions.max = 2147483647;
    }
  }

  handleFilters(gridInstance: DxDataGrid): boolean {
    const isFilter = data => {
      return (Array.isArray(data) && !(Array.isArray(data[2]) && data[2].length === 0));
    };
    const filters = gridInstance.getCombinedFilter() || [];
    const filterAvailable = filters.hasOwnProperty('columnIndex')
      ? isFilter(filters)
      : filter(filters, (f) => isFilter(f)).length > 0;

    const headerFilterRows = gridInstance.element().querySelectorAll('.dx-datagrid-headers .dx-datagrid-filter-row');
    Array.prototype.map.call(headerFilterRows, (headerFilterRow: Element) => {
      const textEditors = headerFilterRow.querySelectorAll('.dx-editor-container .dx-texteditor:not(.dx-dropdowneditor)');
      const changeClassForInput = (hasFilter: boolean, searchElement: Element) => {
        const inputElement = searchElement.querySelector('input.dx-texteditor-input');
        if (hasFilter) {
          inputElement.classList.add('has-filter');
        } else {
          inputElement.classList.remove('has-filter');
        }
      };
      textEditors.forEach((editorElement: Element) => {
        const hasFilter = !editorElement.classList.contains('dx-texteditor-empty');
        changeClassForInput(hasFilter, editorElement);
      });

      const dropdownEditors = headerFilterRow.querySelectorAll('.dx-editor-container .dx-texteditor.dx-dropdowneditor:NOT(.dx-tagbox)');
      dropdownEditors.forEach((editorElement: Element) => {
        const hiddenInput = editorElement.querySelector('input[type=hidden]') as HTMLInputElement;  // combobox
        const hasFilter = hiddenInput ? !!hiddenInput.value : false;
        changeClassForInput(hasFilter, editorElement);
      });

      const tagboxEditors = headerFilterRow.querySelectorAll('.dx-editor-container .dx-texteditor.dx-dropdowneditor.dx-tagbox');
      tagboxEditors.forEach((editorElement: Element) => {
        const tagbox = editorElement.querySelector('.dx-tag') as HTMLInputElement;  // tagbox
        const hasFilter = !!tagbox;
        changeClassForInput(hasFilter, editorElement);
      });
    });

    return filterAvailable;
  }

  getReportKeyColumn(reportTypeId: number) {
    let reportKeyColumn;
    switch (reportTypeId) {
      case ReportType.TransactionHeaderReport:
        reportKeyColumn = 'TransactionId';
        break;
      case ReportType.WorkOrderReport:
        reportKeyColumn = 'WorkOrderId';
        break;
      case ReportType.TransactionStatisticsReport:
        reportKeyColumn = 'AssignmentId';
        break;
      default:
        reportKeyColumn = 'EntityId';
        break;
    }
    return reportKeyColumn;
  }

  getExtraRequiredColumns(reportTypeId: number): Array<string> {
    switch (reportTypeId) {
      case ReportType.TransactionHeaderReport:
        return [
          'IsDraft',
          'TransactionTypeId',
          'TransactionId',
          'TransactionNumber'
        ];
      case ReportType.WorkOrderReport:
        return [
          'AssignmentId',
          'WorkOrderId',
          'WorkOrderVersionId',
          'WorkOrderNumber',
          'ContactFirstName',
          'ContactLastName'
        ];
      case ReportType.BillingTransactionReport:
        return [
          'TransactionHeaderId',
          'BillingTransactionNumber'
        ];
      case ReportType.PaymentTransactionReport:
        return [
          'CurrencyId',
          'PayeeTypeId',
          'PaymentPaymentDate',
          'PaymentId',
          'PaymentNumber',
          'PaymentTotal',
          'PaymentStatusId',
          'PaymentTransactionNumber',
          'TransactionHeaderId'
        ];
      case ReportType.PaymentReport:
        return [
          'CurrencyId',
          'PayeeTypeId',
          'PaymentDate',
          'PaymentId',
          'PaymentNumber',
          'AmountPayment',
          'PaymentStatusId'
        ];
      case ReportType.PeopleAllReport:
        return [
          'ContactId',
          'ProfileType',
          'FirstName',
          'LastName'
        ];
      case ReportType.PurchaseOrderByWorkorder:
        return [
          'WorkorderAllocated'
        ];
      case ReportType.OrgAllReport:
        return [
          'LegalName',
          'OrganizationRoleIdsDisplayValue'
        ];
      default:
        return [];
    }
  }

  private parseRoutingConfigTemplate(template, map, isCondition) {
    let isNull = false;
    const route = template.replace(/\${.+?}/g, match => {
      const path = match.substr(2, match.length - 3).trim();
      if (path.length > 0 && map[path] && isCondition) {
        return map[path].length > 0 ? `'${map[path]}'` : map[path];
      } else if (path.length > 0 && map[path]) {
        return map[path];
      } else {
        isNull = true;
      }
    });
    return isNull ? null : route;
  }

  private getGridColumnFormat(typeId: number): PhxDataTableColumnFormat {
    const dataType = this.getCodeValue('report.CodeReportColumnType').find(c => c.id === typeId).value;
    return dataType === 'decimal' ? { type: 'fixedPoint', precision: 2 } : null;
  }

  private getGridColumnDataType(typeId): string {
    const dataType = this.getCodeValue('report.CodeReportColumnType').find(c => c.id === typeId).value;
    return dataType === 'decimal' ? 'number' : dataType;
  }

  private getLookup(datasource: Array<CodeValue>) {
    return datasource.map(codeValue => {
        let columnValue;
        if (codeValue.groupName === 'geo.CodeCurrency') {
          columnValue = codeValue.code;
        } else if (codeValue.groupName === 'usr.CodeDentalBenefit') {
          columnValue = codeValue.description;
        } else {
          columnValue = codeValue.text;
        }

        return {
            code: codeValue.code,
            value: codeValue.id || codeValue.text,
            text: columnValue
        };
    });
}

  private getWorkflowServerLookup(datasource: Array<CodeValue>) {
    return datasource.map(codeValue => {
      return {
        value: codeValue.code,
        text: codeValue.text
      };
    });
  }

  private getDataColumnLookup(val: any, cultureId: number, organizations?: Array<{ text: string, value: string; }>,
    clientOrganizations?: Array<{ text: string, value: string; }>, branches?: Array<{ text: string, value: string; }>, resolvedData: Data = {}): PhxDataColumnLookup {
      if (val.datasourceName != null) {
      if (val.datasourceName.match(/.Code/)) {
        return {
          dataSource: this.getLookup(this.codeValueService.getCodeValues(val.datasourceName, true).filter(codeValue => {
            if (val.reportTypeId === ReportType.OnboardingReport && val.datasourceName === CodeValueGroups.EntityType) {
                return [PhxConstants.CodeEntityType.ExtensionPackage, PhxConstants.CodeEntityType.OnboardingPackage, PhxConstants.CodeEntityType.RevisionPackage].some(i => i === codeValue.code);
            }
            return true;
          }))?.sort((a, b) => a.text.localeCompare(b.text)),
          // TODO: enable saving codes instead of ids for all reports
          valueExpr: val.reportTypeId === ReportType.OnboardingReport && val.columnTypeId === 2 ? 'code' : 'value',
          displayExpr: 'text'
        };
      } else if (val.datasourceName.match(/^Code/)) {
        return {
          dataSource: this.getWorkflowServerLookup(this.workflowDisplayNameService.getCodeValues(val.datasourceName, true))?.sort((a, b) => a.text.localeCompare(b.text)),
          valueExpr: 'value',
          displayExpr: 'text'
        };
      } else if (val.datasourceName.match(/^GlobalConfiguration\.(.*)$/)) {
        const groupName = val.datasourceName.match(/^GlobalConfiguration\.(.*)$/)[1];
        return {
          dataSource: resolvedData.globalConfiguration[groupName],
          valueExpr: 'value',
          displayExpr: 'text'
        };
      } else if (val.datasourceName === 'GetInternalOrganizations') {
        return {
          dataSource: organizations?.sort((a, b) => a.text.localeCompare(b.text)),
          valueExpr: val.columnTypeId === 1 ? 'value' : 'text',
          displayExpr: 'text'
        };
      } else if (val.datasourceName === 'GetClientOrganizations') {
        return {
          dataSource: clientOrganizations?.sort((a, b) => a.text.localeCompare(b.text)),
          valueExpr: val.columnTypeId === 1 ? 'value' : 'text',
          displayExpr: 'text'
        };
      } else if (val.datasourceName === 'GetBranches') {
        return {
          dataSource: branches?.sort((a, b) => a.text.localeCompare(b.text)),
          valueExpr: val.columnTypeId === 1 ? 'value' : 'text',
          displayExpr: 'text'
        };
      } else if (val.datasource !== null) {
        return {
          dataSource: cultureId === CultureType.EnCA ? val.datasource.sort((a, b) => a.text.localeCompare(b.text)) :
            val.datasource.sort((a, b) => (a.frenchText ?? '').localeCompare(b.frenchText ?? '')),
          valueExpr: val.columnTypeId === 1 ? 'reportFilterId' : 'code',
          displayExpr: cultureId === CultureType.EnCA ? 'text' : 'frenchText'
        };
      }
    } else {
      return null;
    }
  }

}
